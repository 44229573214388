/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useEffect, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero } from "../../components";
// import { Hero, Statement } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/Subscribe_hero2.jpg";
import classNames from "classnames";
// import { ResearchComponent } from "../../components/ResearchDocument";

import leftIcon from "../../images/Left Button.svg";
import rightIcon from "../../images/Right Button.svg";
import { subscribe } from "graphql";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
type Service = {
  name: string;
  checked: boolean;
};
const Accordion: React.FC<{
  title: string;
  content: Service[] | string;
  type: string;
  selectAll: (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>,
    setUpdatedContent: React.Dispatch<
      React.SetStateAction<Service[] | Industry[]>
    >,
    setSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
}> = ({ title, content, type, selectAll }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [updatedContent, setUpdatedContent] = useState<Service[] | string>(
    content
  );
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleToggle = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!content || typeof content === "string") return; // Check if content is null or undefined
    const updatedContentArray = [...(updatedContent as Service[])];
    updatedContentArray[index].checked = e.target.checked;
    setUpdatedContent(updatedContentArray);
    const allChecked = updatedContentArray.every((service) => service.checked);
    setSelectAllChecked(allChecked);
  };

  return (
    <div className="accordion-item border-b border-gray-200 lg:p-20px md:pt-20px pt-20px">
      <a
        onClick={toggleAccordion}
        className="w-full text-left p-4 flex justify-between items-center"
      >
        <strong>{title}</strong>
        <div className="float-right">
          <span className="mr-10px">Select all</span>
          <label className="switch">
            <input
              type="checkbox"
              name="selectAll"
              id="select-all"
              checked={selectAllChecked}
              onChange={(e) =>
                selectAll(title, e, setUpdatedContent, setSelectAllChecked)
              }
            />
            <span className="slider round"></span>
          </label>
        </div>
      </a>
      {isOpen && typeof updatedContent !== "string" && (
        <div className="p-4 bg-gray-100">
          <div className="page-grid lg:grid-cols-2 grid-cols-1 text-left mt-10px">
            {(updatedContent as Service[]).map((service, index) => (
              <div key={index} className="mb-10px page-grid grid-cols-2">
                <div className="lg:w-desktop-2-cols w-mobile-12-cols md:w-desktop-2-cols">
                  <span className="mr-10px">{service.name}</span>
                </div>
                <div>
                  <label className="switch float-right">
                    <input
                      type="checkbox"
                      id={`service-${index}`}
                      name={service.name}
                      checked={service.checked}
                      onChange={(e) => handleToggle(index, e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isOpen && typeof updatedContent === "string" && (
        <div className="p-4 bg-gray-100">
          <p>{updatedContent}</p>
        </div>
      )}
    </div>
  );
};

const AccordionSection: React.FC = () => {
  const [services, setServices] = useState([
    { name: "Capital Projects", checked: false },
    { name: "Continuous Improvement", checked: false },
    { name: "Digital Transformation", checked: false },
    { name: "Energy Transition", checked: false },
    { name: "Organisational Effectiveness", checked: false },
    { name: "Prescriptive Analytics", checked: false },
    { name: "Procurement and Supply Chain", checked: false },
    { name: "Transaction Services", checked: false },
    { name: "WiredUp Software Suite", checked: false },
    // Add more services as needed
  ]);
  const [industries, setIndustries] = useState([
    { name: "Consumer Goods and Retail", checked: false },
    { name: "Financial Services", checked: false },
    { name: "Mining and Metals", checked: false },
    { name: "Oil and Gas", checked: false },
    { name: "Public Sector", checked: false },
    // Add more industries as needed
  ]);

  const selectAll = (type, e, setUpdatedContent, setSelectAllChecked) => {
    const isChecked = e.target.checked;
    const updatedItems =
      type === "Services"
        ? services.map((service) => ({ ...service, checked: isChecked }))
        : industries.map((industry) => ({ ...industry, checked: isChecked }));

    if (type === "Services") {
      setServices(updatedItems);
    } else {
      setIndustries(updatedItems);
    }

    setUpdatedContent(updatedItems);
    setSelectAllChecked(isChecked);
  };

  return (
    <section className="accordion-section p-4">
      <Accordion
        title="Services"
        content={services}
        type="services"
        selectAll={selectAll}
      />
      <Accordion
        title="Industries"
        content={industries}
        type="industries"
        selectAll={selectAll}
      />
    </section>
  );
};

const ResearchDocument: React.FC<
  PageProps<
    Pick<
      Query,
      | "allGraphCmsResearchDocument"
      | "graphCmsSectorPage"
      | "allGraphCmsGaPress"
      | "allGraphCmsGaSubscribe"
    >
  >
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    allGraphCmsGaPress: pressnodes,
    graphCmsSectorPage: sectorPage,
    allGraphCmsGaSubscribe: Subscribe,
  },
}) => {
  const [filteredData, setFilteredData] = useState(
    // nodes.filter((test) => test.mostPopular !== true)
    pressnodes.nodes.filter((test) => test.mostPopular !== true)
  );
  const [sub_OriginalData, setsub_OriginalData] = useState(
    // nodes.filter((test) => test.mostPopular !== true)
    Subscribe.nodes.filter((test) => test.isThatMainWindow !== "")
  );
  const [sub_filteredData, setsub__FilteredData] = useState(
    // nodes.filter((test) => test.mostPopular !== true)
    Subscribe.nodes.filter((test) => test.isThatMainWindow === "No")
  );
  const [sub_featuredData, setsub_featuredData] = useState(
    // nodes.filter((test) => test.mostPopular !== true)
    Subscribe.nodes.filter((test) => test.isThatMainWindow === "Yes")
  );
  const [featuredData, setfeaturedData] = useState(
    // nodes.filter((test) => test.mostPopular !== true)
    pressnodes.nodes.filter((test) => test.mostPopular !== true)
  );
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const [startIndex, setStartIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const selectAll = (type, e) => {
    const isChecked = e.target.checked;
    const updatedItems =
      type === "services"
        ? services.map((service) => ({ ...service, checked: isChecked }))
        : industries.map((industry) => ({ ...industry, checked: isChecked }));
    type === "services"
      ? setServices(updatedItems)
      : setIndustries(updatedItems);
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    emailAddress: "",
    subscription: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // setSubmitting(true);
      const errorMessage = document.getElementById("error");
      errorMessage.textContent = "";
      // Array of keys to check against
      const keyArray = [
        "Capital Projects",
        "Continuous Improvement",
        "Digital Transformation",
        "Energy Transition",
        "Organisational Effectiveness",
        "Prescriptive Analytics",
        "Procurement and Supply Chain",
        "Transaction Services",
        "WiredUp Software Suite",
        "Consumer Goods and Retail",
        "Financial Services",
        "Mining and Metals",
        "Oil and Gas",
        "Public Sector",
      ];

      // Initialize the counter
      let count = 0;
      const form = e.currentTarget;
      const formData = new FormData(form);
      const serialized: { [key: string]: FormDataEntryValue } = {};
      formData.forEach((v, k) => {
        serialized[k] = v;
        if (keyArray.includes(k)) {
          count++;
        }
      });
      // console.log(JSON.stringify(serialized)["Capital Projects"]);
      if (count > 0) {
        try {
          const response = await fetch(
            "https://prod-184.westeurope.logic.azure.com:443/workflows/d1f18899f1fc4fe0b9fa6918cabe1aa5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=48RjH9nvUTxvQKMJk5JJSYjC1Q-v4XT1xdt0B_TS7EU",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(serialized),
            }
          );

          if (response.status === 202) {
            console.log("Success");
            alert("Form submitted successfully!");
          } else {
            console.log("Failed");
            alert("Form submission failed!");
          }
        } catch (error) {
          console.error("Error:", error);
          alert("An error occurred. Please try again.");
        }
      } else {
        errorMessage.textContent =
          "Please select any one of the Services or Indusries";
        // alert("nothing selected");
      }
    }
    // [postUrl]
  );
  nodes.map((n) => console.log(n));
  function TruncateText({ text, maxWords, classes }) {
    const [truncatedText, setTruncatedText] = useState(text);
    useEffect(() => {
      if (text) {
        const words = text[0].split(" ");
        if (words.length > maxWords) {
          const truncated = words.slice(0, maxWords).join(" ");
          setTruncatedText(`${truncated}...`);
        }
      } else {
        setTruncatedText("Sample Text");
      }
    }, [text, maxWords]);

    return <p className={classes}>{truncatedText}</p>;
  }
  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - (isMobile ? 1 : 1), 0));
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => {
      const itemsPerPage = isMobile ? 1 : 1;
      const maxIndex = sub_OriginalData.length - itemsPerPage;
      return Math.min(prevIndex + itemsPerPage, maxIndex);
    });
  };
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords="management consulting, business transformation, business resilience, cost management, business innovation"
      title="Latest insights from our experts | Partners in Performance | Global Management Consultancy"
      description="Read the latest insights and research from our industry experts | Partners in Performance"
    >
      <Hero
        title="Subscribe to the latest insights from our industry experts"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <section className="padding8_5 padding_site_match pt-10px pb-10px page-grid lg:grid-cols-2 grid-cols-1">
        <>
          <div>
            <p className="leading-3  mt-30px mb-30px">
              <h2 className="col-start-2 col-span-12 p1">
                Featured insights and perspectives
              </h2>
              <br></br>
              <span className="leading-tight">
                Explore our latest thoughts on the trends impacting businesses
                around the world.
              </span>
              <br></br>
            </p>
            <section className="w-full lg:block hidden">
              <div className="relative overflow-hidden scrollable-container">
                <div className="flex space-x-4">
                  {/* {featuredData
                    .slice(
                      startIndex,
                      startIndex + (window.innerWidth > 768 ? 1 : 1)
                    )
                    .map((data, index) => {
                      return (
                        <div
                          key={startIndex + index}
                          className={window.innerWidth > 768 ? "" : "w-full"}
                        >
                          <a href={"/en/" + data.fullArticleLink}>
                            <div className="w-full">
                              <img
                                src={data.thumbnail.url}
                                alt={`Image ${startIndex + index + 1}`}
                                className="w-full m-auto rounded-tl-lg rounded-tr-lg"
                              />
                            </div>
                            <div className="p-35px  bg-white-1 rounded-lg">
                              <span className="p2">
                                {data.listTitle
                                  ? data.listTitle
                                  : data.articleTitle}
                              </span>
                            </div>
                          </a>
                        </div>
                      );
                    })} */}
                  {sub_featuredData
                    .slice(
                      startIndex,
                      startIndex + (window.innerWidth > 768 ? 1 : 1)
                    )
                    .map((data, index) => {
                      return (
                        <div
                          key={startIndex + index}
                          className={window.innerWidth > 768 ? "" : "w-full"}
                        >
                          {/* Image */}
                          <a
                            href={data.downloadAsset.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="w-full">
                              <img
                                src={data.image.url}
                                alt={`Image ${startIndex + index + 1}`}
                                className="w-full m-auto rounded-tl-lg rounded-tr-lg"
                              />
                            </div>
                            {/* Article title */}
                            <div className="p-35px  bg-white-1 rounded-lg">
                              {/* <p className="text-14">
                                {parts[0] +
                                  " " +
                                  feature_monthInCamelCase +
                                  " " +
                                  parts[2]}
                              </p> */}
                              <span className="p2">{data.title}</span>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
                {/* <button
                  onClick={handlePrev}
                  className="absolute top-1/2 left-0 lg:left-30px md:left-30px transform -translate-y-1/2 text-white text-2xl"
                >
                  <img src={leftIcon} alt="Left" />
                </button>
                <button
                  onClick={handleNext}
                  className="absolute top-1/2 right-0 lg:right-30px md:right-30px transform -translate-y-1/2 text-white text-2xl"
                >
                  <img src={rightIcon} alt="Left" />
                </button> */}
              </div>
            </section>
            <section className="w-full lg:hidden ">
              <div className="relative overflow-hidden scrollable-container">
                <div className="flex space-x-4 p-30px">
                  {/* {filteredData
                    .slice(
                      startIndex,
                      startIndex + (window.innerWidth > 768 ? 1 : 1)
                    )
                    .map((data, index) => {
                      const featuredformattedDate = new Date(
                        data.publishingDate
                      ).toLocaleString("en-in", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      });

                      const parts = featuredformattedDate.split(" ");
                      const feature_monthInCamelCase =
                        parts[1].charAt(0).toUpperCase() +
                        parts[1].slice(1).toLowerCase();
                      return (
                        <div
                          key={startIndex + index}
                          className={
                            window.innerWidth > 768 ? "pr-15px" : "w-full"
                          }
                        >
                          <a href={"/en/" + data.fullArticleLink}>
                            <div className="w-full">
                              <img
                                src={data.thumbnail.url}
                                alt={`Image ${startIndex + index + 1}`}
                                className="w-full m-auto h-auto rounded-tl-lg rounded-tr-lg"
                              />
                            </div>
                            <div className="p-35px  bg-white-1 rounded-lg">
                              <span className="p2">
                                {data.listTitle
                                  ? data.listTitle
                                  : data.articleTitle}
                              </span>
                            </div>
                          </a>
                        </div>
                      );
                    })} */}
                  {sub_OriginalData
                    .slice(
                      startIndex,
                      startIndex + (window.innerWidth > 768 ? 1 : 1)
                    )
                    .map((data, index) => {
                      return (
                        <div
                          key={startIndex + index}
                          className={
                            window.innerWidth > 768 ? "pr-15px" : "w-full"
                          }
                        >
                          {/* Image */}
                          <a
                            href={data.downloadAsset.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="w-full">
                              <img
                                src={data.image.url}
                                alt={`Image ${startIndex + index + 1}`}
                                className="w-full m-auto h-auto rounded-tl-lg rounded-tr-lg"
                              />
                            </div>
                            {/* Article title */}
                            <div className="p-35px  bg-white-1 rounded-lg">
                              <span className="p2">{data.title}</span>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
                <button
                  onClick={handlePrev}
                  className="absolute top-1/2 left-0 lg:left-30px md:left-30px transform -translate-y-1/2 text-white text-2xl"
                >
                  <img src={leftIcon} alt="Left" />
                </button>
                <button
                  onClick={handleNext}
                  className="absolute top-1/2 right-0 lg:right-30px md:right-30px transform -translate-y-1/2 text-white text-2xl"
                >
                  <img src={rightIcon} alt="Left" />
                </button>
              </div>
            </section>
            <div className="page-grid grid-cols-2 hidemobile">
              {/* {filteredData.slice(1).map((test, index) => {
                console.log(test.thumbnail.url);
                const formattedDate = new Date(
                  test.publishingDate
                ).toLocaleString("en-in", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                });

                const parts = formattedDate.split(" ");
                const monthInCamelCase =
                  parts[1].charAt(0).toUpperCase() +
                  parts[1].slice(1).toLowerCase();
                return (
                  <a
                    key={index}
                    href={
                      test.linkType == "Internal"
                        ? "/en/" + test.fullArticleLink
                        : test.sourceLink
                    }
                    target={test.linkType == "Internal" ? "" : "_blank"}
                    className={classNames("")}
                    rel="noreferrer"
                  >
                    <div className="bg-white-1 rounded-lg   lg:mt-20px md:mt-10px mt-10px">
                      <img
                        src={test.thumbnail.url}
                        alt="Image 1"
                        className="w-full rounded-tl-lg rounded-tr-lg"
                      />
                      <div className="p-30px">
                        <p className=" mb-10px h-70px overflow-hidden">
                          {test.listTitle ? (
                            <TruncateText
                              text={test.listTitle}
                              maxWords={10}
                              classes=" h-60px"
                            />
                          ) : (
                            <TruncateText
                              text={test.articleTitle}
                              maxWords={10}
                              classes=" h-60px"
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  </a>
                );
              })} */}
              {sub_filteredData.map((data, index) => {
                return (
                  <div
                    key={startIndex + index}
                    className={window.innerWidth > 768 ? "" : "w-full"}
                  >
                    {/* Image */}
                    <a
                      href={data.downloadAsset.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="bg-white-1 rounded-lg   lg:mt-20px md:mt-10px mt-10px">
                        <img
                          src={data.image.url}
                          alt="Image 1"
                          className="w-full rounded-tl-lg rounded-tr-lg"
                        />
                        <div className="p-30px">
                          <p className=" mb-10px ga-h-50px overflow-hidden">
                            <TruncateText
                              text={data.title}
                              maxWords={10}
                              classes=""
                            />
                          </p>
                        </div>
                      </div>
                      {/* <div className="p-35px  bg-white-1 rounded-lg">
                        <span className="p2">{data.title}</span>
                      </div> */}
                    </a>
                  </div>
                );
              })}
              {/* {nodes.map((test) => (
                <>
                  <a href={test.documents.url} target="_blank" rel="noreferrer">
                    <div className="bg-white-1 rounded-lg   lg:m-15px md:m-10px m-10px">
                      <img
                        src={test.documentThumbnail.url}
                        alt="Image 1"
                        className="w-full rounded-tl-lg rounded-tr-lg"
                      />
                      <div className="p-30px">
                        <div className="flex justify-between text-14 text-grey mb-5px"></div>
                        <p className=" mb-10px h-70px overflow-hidden">
                          <TruncateText
                            text={test.documentDescription}
                            maxWords={15}
                            classes="h4 font-semibold mb-10px"
                          />
                        </p>
                      </div>
                    </div>
                  </a>
                </>
              ))} */}
            </div>
          </div>
          <div>
            <div className=" page-grid lg:grid-cols-1 grid-cols-1 ">
              <div className="relative top-30px">
                <p className="leading-3">
                  <h2 className="col-start-2 col-span-12 p1 text-left lg:pl-30px pl-0">
                    Looking for more insights?
                  </h2>
                  <br></br>
                  <h3 className="leading-tight  text-left lg:pl-30px pl-0">
                    Subscribe to have the latest thinking delivered right into
                    your inbox.
                  </h3>
                </p>
              </div>
              <div className="">
                <form
                  className={classNames("lg:p-30px p-0 mt-30px lg:mt-0")}
                  onSubmit={handleSubmit}
                >
                  <div className="lg:grid-cols-12 grid-cols-1 page-grid">
                    <div
                      className={classNames(
                        "pt-20px col-span-12",
                        "lg:col-span-6"
                      )}
                    >
                      <input
                        key="first Name"
                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                        className="w-full border-none"
                        type="text"
                        name="firstName"
                        required={true}
                        placeholder="First name"
                        id="firstName"
                      />
                      <span
                        className={classNames(
                          "text-purple hidden text-left ga-font-12"
                        )}
                        id={"hidefieldFirstName"}
                      >
                        This field is required
                      </span>
                    </div>
                    <div
                      className={classNames(
                        "pt-20px col-span-12",
                        "lg:col-span-6"
                      )}
                    >
                      <input
                        key="Last Name"
                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                        className="w-full border-none"
                        type="text"
                        name="lastName"
                        required={true}
                        placeholder="Last name"
                        id="lastName"
                      />
                      <span
                        className={classNames(
                          "text-purple hidden text-left ga-font-12"
                        )}
                        id={"hidefieldLastName"}
                      >
                        This field is required
                      </span>
                    </div>
                    <div
                      className={classNames(
                        "pt-20px col-span-12",
                        "lg:col-span-6"
                      )}
                    >
                      <input
                        key="Company"
                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                        className="w-full border-none"
                        type="text"
                        name="Company"
                        required={true}
                        placeholder="Company"
                        id="Company"
                      />
                      <span
                        className={classNames(
                          "text-purple hidden text-left ga-font-12"
                        )}
                        id={"hidefieldCompany"}
                      >
                        This field is required
                      </span>
                    </div>
                    <div
                      className={classNames(
                        "pt-20px col-span-12",
                        "lg:col-span-6"
                      )}
                    >
                      <input
                        key="Job title"
                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                        className="w-full border-none"
                        type="text"
                        name="jobTitle"
                        required={true}
                        placeholder="Job title"
                        id="jobTitle"
                      />
                      <span
                        className={classNames(
                          "text-purple hidden text-left ga-font-12"
                        )}
                        id={"hidefieldjobTitle"}
                      >
                        This field is required
                      </span>
                    </div>
                    <div
                      className={classNames(
                        "pt-20px col-span-12",
                        "lg:col-span-12"
                      )}
                    >
                      <input
                        key="Email address"
                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                        className="w-full border-none"
                        type="text"
                        name="emailAddress"
                        required={true}
                        placeholder="Email address"
                        id="emailAddress"
                      />
                      <span
                        className={classNames(
                          "text-purple hidden text-left ga-font-12"
                        )}
                        id={"hidefieldemailAddress"}
                      >
                        This field is required
                      </span>
                    </div>
                    <div
                      className={classNames(
                        "pt-20px col-span-12",
                        "lg:col-span-12"
                      )}
                    >
                      <strong className="text-20">
                        Subscribe to the following
                      </strong>
                      <br></br>
                      <AccordionSection />
                    </div>
                  </div>
                  <div className="lg:grid-cols-12 grid-cols-1 page-grid">
                    {
                      <div
                        className={classNames(
                          "lg:col-span-12 col-span-12 mt-10px text-left"
                        )}
                      >
                        <div className="w-full pt-20px">
                          <p className="m-auto">
                            Partners in Performance, Part of Accenture would
                            like to occasionally share news about our services
                            and career opportunities via e-mail when we believe
                            it would be relevant to you. Would you like to
                            opt-in to these communications? Note – you may
                            withdraw your consent at any time using the
                            opt-out/preference link in the emails sent.
                            <br></br>
                            <br></br>
                            <input
                              type="checkbox"
                              className="form-control"
                              name="subscription"
                              required={true}
                              // defaultChecked
                            />
                            &nbsp;&nbsp;Yes, you may use my personal data to
                            send me relevant information in accordance with the{" "}
                            <a href="https://www.accenture.com/sg-en/support/privacy-policy">
                              <u>Accenture Privacy Privacy Statement</u>
                            </a>{" "}
                          </p>
                        </div>
                        {/* <div className="w-full pt-20px">
                          <p className="m-auto">
                            <input
                              type="checkbox"
                              className="form-control"
                              name="subscription_personal"
                              required={true}
                            />
                            &nbsp;&nbsp;Yes, you may use my personal data to
                            send me relevant information.
                          </p>
                        </div> */}
                        <div className="w-full pt-20px">
                          <p className="m-auto">
                            Partners in Performance was acquired by Accenture on
                            Aug 29, 2024.
                          </p>
                        </div>
                        <p className="text-purple font-medium" id="error"></p>
                      </div>
                    }
                    <div className="lg:col-span-6 col-span-12 items-center mt-30px">
                      <div className="w-full">
                        <button
                          type="submit"
                          className="col-span-1 col-span-6 md:col-start-1 md:col-span-3  p-0 grid ga-card-button w-full ga-max-width-300px float-left"
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      </section>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsGaSubscribe {
      nodes {
        isThatMainWindow
        image {
          linkUrl
          url
        }
        title
        downloadAsset {
          url
        }
      }
    }
    allGraphCmsResearchDocument(
      limit: 4
      sort: { fields: publishedBy___publishedAt, order: DESC }
    ) {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    allGraphCmsGaPress(
      filter: { locale: { eq: en } }
      sort: { fields: publishingDate, order: DESC }
      limit: 3
    ) {
      nodes {
        mostPopular
        articleContent {
          cleaned
        }
        articleTitle
        listTitle
        listDescription
        createdAt
        linkType
        fullArticleLink
        gaAuthors {
          authorName
          authorLinks
        }
        sourceLink
        relatedRegions
        thumbnail {
          url
        }
        publishingDate
        relatedServices {
          sectorType
          sector
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
